import React from 'react';
import '../styles/LoadingSpinner.css'; // Importing the CSS file for cleaner code

const LoadingSpinner = ({ message = "Loading..." }) => {
    return (
            <div className="loading-container">
                <div className='spinner-box'>
                    <div className="spinner"></div>
                    <p className="loading-text">{message}</p>
                </div>
            </div>

            

    );
};

export default LoadingSpinner;
