import React, { Component } from 'react';
import './../styles/ErrorBoundary.css'; // Import the styled CSS animation

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    // Update state when an error is caught
    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error caught in ErrorBoundary:', error, errorInfo);
        this.setState({ error, errorInfo });
    }

    handleReload = () => {
        window.location.reload(); // Refresh the page on button click
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary-container">
                    <div className="error-icon">😞</div>
                    <h1 className="error-title">Oops! Something Went Wrong.</h1>
                    <p className="error-description">
                        We're working on fixing the issue. Try refreshing the page or return later.
                    </p>
                    <button className="error-button" onClick={this.handleReload}>
                        Refresh Page
                    </button>
                    <details className="error-details">
                        <summary>Click for error details</summary>
                        <p>{this.state.error?.toString()}</p>
                        <p>{this.state.errorInfo?.componentStack}</p>
                    </details>
                </div>
            );
        }

        return this.props.children;  // Render children if no error
    }
}

export default ErrorBoundary;
